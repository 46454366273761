<template>
  <div class="md-layout">
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <div class="md-layout-item md-size-50 md-small-size-100 d-flex">
      <div class="d-flex flex-column w-100">
        <div class="flex-fill m-2 overflow-y-auto m-2 p-2 scroll">
          <ul class="search-list">
            <li v-for="(dr, index) in doctors" v-bind:key="index">
              <div class="md-layout">
                <div class="md-layout-item md-size-25">
                  <img
                    class="search-user-img"
                    src="@/assets/img/user_avatar.png"
                    alt="search users"
                  />
                </div>
                <div class="md-layout-item md-size-75 d-flex flex-column">
                  <h3>{{ dr.person.firstName }} {{ dr.person.lastName }}</h3>
                  <p class="qualification" v-if="dr.qualification">
                    <span
                      >{{
                        qualifications.find(
                          (qual) => qual.id === dr.qualification
                        ).displayValue
                      }},
                    </span>
                    <span v-if="dr.practice"> {{ dr.practice }}</span>
                  </p>
                  <div class="d-flex justify-content-between">
                    <p>
                      <i class="fa fa-map-marker"></i>
                      <span v-if="dr.person.addresses[0]"
                        >{{ dr.person.addresses[0].address1 }},{{
                          dr.person.addresses[0].city
                        }},&nbsp;&nbsp;{{
                          stateList.find(
                            (st) =>
                              String(st.id) === dr.person.addresses[0].state
                          ) &&
                          stateList.find(
                            (st) =>
                              String(st.id) === dr.person.addresses[0].state
                          ).displayValue
                        }}&nbsp;&nbsp;,
                        {{
                          countryCodeList.find(
                            (country) =>
                              String(country.id) ===
                              dr.person.addresses[0].country
                          ) &&
                          countryCodeList.find(
                            (country) =>
                              String(country.id) ===
                              dr.person.addresses[0].country
                          ).displayValue
                        }},&nbsp;&nbsp;{{ dr.person.addresses[0].zipCode }}
                      </span>
                    </p>
                  </div>
                  <p>
                    <span class="mr-2"
                      ><i class="fa fa-phone"></i>
                      {{ dr.person.cellNumber }}</span
                    >
                    <span v-if="dr.person.webSite"
                      ><i class="fa fa-globe"></i>
                      {{ dr.person.webSite }}&nbsp;</span
                    >
                    <span
                      ><i class="fa fa-envelope"></i>
                      {{ dr.person.email }}</span
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="totalRecords > 0" class="button-container">
          <md-button
            class="md-danger"
            :disabled="previousPage < 0"
            @click="goToPreviousPage"
            >Previous</md-button
          >
          <md-button
            class="md-primary"
            :disabled="nextPage < 0"
            @click="goToNextPage"
            >Next</md-button
          >
        </div>
      </div>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <md-card class="card-rounded p-4">
        <div class="d-flex justify-content-center align-item-center mb-3">
          <img
            class="reg-logo"
            src="@/assets/img/GlobeChek.png"
            alt="Globe Check Logo"
          />
          <p class="md-subheading m-0 ml-3">Search Doctor</p>
        </div>
        <p class="m-0 pb-2" v-if="totalRecords > -1">
          {{ totalRecords }}&nbsp;Records Found
        </p>
        <div class="d-flex">
          <div class="w-50 mr-2">
            <select-input
              name="qualification"
              displayName="Qualification"
              placeHolder="Qualification"
              :options="
                qualifications.map((x) => ({
                  value: x.id,
                  text: x.displayValue,
                }))
              "
              v-model="doctorSearchForm.qualifictn"
            />
          </div>
          <div class="w-50">
            <select-input
              name="specialization"
              displayName="Eye Care Provider"
              placeHolder="Eye Care Provider"
              :options="
                specializations.map((x) => ({
                  value: x.id,
                  text: x.displayValue,
                }))
              "
              v-model="doctorSearchForm.speclztn"
            />
          </div>
        </div>

        <div>
          <text-input
            label="Name "
            placeholder="Name "
            icon="perm_identity"
            v-model="doctorSearchForm.name"
            icons="perm_identity"
          />
        </div>
        <div>
          <text-input
            type="text"
            label="Mailing Address "
            placeholder="Mailing Address "
            icon="place"
            v-model="doctorSearchForm.addr1"
            icons="room"
          />
        </div>
        <div class="d-flex">
          <div class="w-50 mr-2">
            <text-input
              type="text"
              label="City"
              placeholder="City"
              icon="location_city"
              v-model="doctorSearchForm.cty"
              icons="domain"
            />
          </div>
          <div class="w-50">
            <select-input
              name="state"
              displayName="State"
              placeHolder="state"
              :options="
                stateList.map((x) => ({
                  value: x.id,
                  text: x.displayValue,
                }))
              "
              v-model="doctorSearchForm.state"
            />
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50 mr-2">
            <select-input
              name="country"
              displayName="Country"
              placeHolder="country"
              icon="outlined_flag"
              :options="
                countryCodeList.map((x) => ({
                  value: x.id,
                  text: x.displayValue,
                }))
              "
              v-model="doctorSearchForm.country"
            />
          </div>
          <div class="w-50">
            <text-input
              type="text"
              label="Zip Code"
              placeholder="Zip Code"
              :required="true"
              v-model="doctorSearchForm.zip"
            />
          </div>
        </div>
        <div class="button-container">
          <md-button class="md-primary" @click="searchDoctors()">
            Search
          </md-button>
          <md-button class="md-danger" @click="clearSearch"> Clear </md-button>
          <md-button class="md-default" @click="navigateToHomePage">
            Exit
          </md-button>
        </div>
      </md-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TextInput from "../../../components/controls/TextInput.vue";
import * as types from "../../../store/modules/doctor/doctor.action.types";
export default {
  name: "DoctorSearch",
  data() {
    return {
      id: this.$route.params.id,
      doctors: [],
      totalRecords: -1,
      previousPage: -1,
      nextPage: -1,
      doctorSearchForm: this.clearDoctorSearchForm(),
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      let storeActions = mutation.type.split("/");
      let _state = storeActions[0];
      let _action = storeActions[1];
      if (
        Object.keys(state).some((st) => st === _state) &&
        _action === types.SEARCH_DOCTORS
      ) {
        if (state[_state]) {
          this.doctors = mutation.payload.data;
          this.totalRecords = mutation.payload.totalRecords;
          this.previousPage = mutation.payload.previousPage;
          this.nextPage = mutation.payload.nextPage;
        }
      }
    });
  },
  components: { TextInput },
  inject: [
    "navigateToHomePage",
    "searchDoctorWithParams",
    "clearDoctorSearchForm",
    "app",
  ],
  computed: {
    ...mapState({
      specializations: (state) => state.master.specializations,
      qualifications: (state) => state.master.qualification,
      stateList: (state) => state.master.stateList,
      countryCodeList: (state) => state.master.countryCodeList,
    }),
  },
  methods: {
    goToNextPage() {
      this.doctorSearchForm.pgNum = this.nextPage;
      this.searchDoctorWithParams(this.doctorSearchForm);
    },
    goToPreviousPage() {
      this.doctorSearchForm.pgNum = this.previousPage;
      this.searchDoctorWithParams(this.doctorSearchForm);
    },
    clearDoctorSearchFormParams() {
      this.clearSearch();
    },
    clearSearch() {
      this.doctors = [];
      this.totalRecords = -1;
      this.previousPage = -1;
      this.nextPage = -1;
      this.doctorSearchForm = this.clearDoctorSearchForm();
    },
    searchDoctors() {
      this.doctorSearchForm.pgNum = 1;
      this.searchDoctorWithParams(this.doctorSearchForm);
    },
  },
};
</script>
